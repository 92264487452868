.logo {
  /* Logo */
  position: absolute;
  top: 65px;
  left: 40px;
  z-index: 1;
}
.home-page .logo img{
  width: 150px;
  height: 30px;
}

/* Main Text */
.home-page .main-text {
  position: absolute;
  top: 150px;
  left: 40px;
  font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif;
  font-size: 4em;
  font-weight: bold;
  color: black;
  text-shadow: none;
  display: flex;
  flex-direction: column;
}
.home-page .main-text h1 {
  margin: 0;
  flex-grow: 1;
}
.home-page .main-text span {
  font-weight: bold;
}

/* Navigation Menu */
.home-page .menu {
  position: absolute;
  top: 65px;
  right: 20px;
  z-index: 1;
  display: flex;
}
.home-page .menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}
.home-page .menu li {
  margin-left: 20px;
}
.home-page .menu a {
  color: #000;
  text-decoration: none;
}
.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.home-page .container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}




/* Map Container */
.map-container {
  text-align: center;
  margin-top: 150px;
  width: 100%;
  height:400px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}